
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ContactConfig from './ContactConfig'

@Component
export default class Contact extends Vue {
  mailElement: string = ContactConfig.generateMailElement();
  telephoneNationalElement: string = ContactConfig.generateTelephoneElement(true);
  telephoneInternationalElement: string = ContactConfig.generateTelephoneElement(false);
}
